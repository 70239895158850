import React, { useState } from "react";
import {
  Typography,
  OutlinedInput,
  Button,
  FormControlLabel,
  Rating,
  Divider,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import {
  useCreateReviewMutation,
  useUpdateReviewMutation,
  useGetReviewByIdQuery,
} from "../../Store/Services/reviewService";
import styles from "./RequestDetails.module.css";
import { useGetRequestByIdQuery } from "../../Store/Services/RequestService";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const RequestDetails = ({ type }) => {
  const { reqId } = useParams();

  const { data: requestData } = useGetRequestByIdQuery(reqId);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  const [isReviewPresent, setIsReviewPresent] = useState(false);

  const [createReview] = useCreateReviewMutation();
  const [updateReview] = useUpdateReviewMutation();

  const [reviewData, setReviewData] = useState(() => {
    const existingReview = requestData?.[0]?.reviews?.[0];
    return existingReview
      ? {
          rating: existingReview.rating,
          review: existingReview.review,
        }
      : {
          rating: 0,
          review: "",
        };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReviewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRatingChange = (_, newValue) => {
    setReviewData((prevData) => ({
      ...prevData,
      rating: newValue,
    }));
  };
  console.log("xxxxxxx", requestData);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const name = `${requestData[0]?.requester?.firstName || ""} ${
        requestData[0]?.requester?.lastName || ""
      }`.trim();

      console.log("name:", name);
      if (isReviewPresent) {
        // Update
        await updateReview({
          reqId,
          reviewId: requestData[0].reviews[0].reviewId,
          status: 1,
          name: name,
          ...reviewData,
        }).unwrap();
        handleDialogClose();
      } else {
        // Create new
        await createReview({
          reqId,
          name: name,
          ...reviewData,
        }).unwrap();
        handleDialogClose();
      }
    } catch (error) {
      console.error("Failed to submit:", error);
    }
  };

  useEffect(() => {
    if (requestData && requestData[0]?.reviews?.length > 0) {
      const existingReview = requestData[0].reviews[0];
      setReviewData({
        rating: existingReview.rating,
        review: existingReview.review,
      });
      setIsReviewPresent(true);
    }
  }, [requestData]);

  return (
    requestData &&
    requestData.length > 0 && (
      <div className={styles.container}>
        <div className={styles.headingTopContainer}>
          <Typography
            className={styles.bookingTitle}
            fontWeight={600}
            variant={"h5"}
          >
            {requestData[0].catName}{" "}
            {requestData[0].freqName && (
              <label>({requestData[0].freqName})</label>
            )}{" "}
          </Typography>

          <Typography className={styles.status}>
            {requestData[0].reqStatus}
          </Typography>
        </div>
        <Typography
          variant="body1"
          fontWeight={600}
          className={styles.requesterLabel}
        >
          Requester :
          {`${requestData[0]?.requester?.firstName || ""} ${
            requestData[0]?.requester?.lastName || ""
          }`}
        </Typography>

        <div className={styles.requestDetailsContainer}>
          <Typography
            className={styles.subTitle}
            fontWeight={600}
            variant={"body1"}
          >
            Service Details
          </Typography>
          <Grid
            container
            sx={{
              gap: { xs: 0, md: "-20px" },
            }}
          >
                        <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].day && (
                  <label className={styles.formLabel}>Date:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].day && (
                  <label className={styles.formLabel}>
                    {requestData[0].day}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].timeSlot && (
                  <label className={styles.formLabel}>Time:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].timeSlot && (
                  <label className={styles.formLabel}>
                    {requestData[0].timeSlot}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].noHrs && (
                  <label className={styles.formLabel}>Duration:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].noHrs && (
                  <label className={styles.formLabel}>
                    {requestData[0].noHrs} hrs
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].noRes && (
                  <label className={styles.formLabel}>
                    No of Professionals:
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].noRes && (
                  <label className={styles.formLabel}>
                    {requestData[0].noRes}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                <label className={styles.formLabel}>Materials:</label>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].isMaterialsNeeded !== undefined && (
                  <label className={styles.formLabel}>
                    {requestData[0].isMaterialsNeeded === 1 ? "Yes" : "No"}
                  </label>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.requestDetailsContainer}>
          <Typography
            className={styles.subTitle}
            fontWeight={600}
            variant={"body1"}
          >
            Price Details
          </Typography>
          <Grid
            container
            sx={{
              gap: { xs: 0, md: "-20px" },
            }}
          >
            {" "}
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].amount && (
                  <label className={styles.formLabel}>Sub Total:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].amount && (
                  <label className={styles.formLabel}>
                    {process.env.REACT_APP_CURRENCY} {requestData[0].amount}
                  </label>
                )}
              </div>
            </Grid>
          </Grid>

          {requestData[0]?.charges?.map((charge, index) => (
            <Grid container spacing={-20} key={index}>
              <Grid item xs={4}>
                <div className={styles.listItem}>
                  <label className={styles.formLabel}>
                    {charge.description} ({charge.chargePercentage}%):
                  </label>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className={styles.listItem}>
                  <label className={styles.formLabel}>
                    {process.env.REACT_APP_CURRENCY} {charge.finalAmount}
                  </label>
                </div>
              </Grid>
            </Grid>
          ))}

          <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />

          <div className={styles.listItem}>
            {requestData[0].paymentMethod && (
              <label className={styles.formLabel}>
                Pay By {requestData[0].paymentMethod}
              </label>
            )}
          </div>
        </div>
        <div className={styles.requestDetailsContainer}>
          <Typography
            className={styles.subTitle}
            fontWeight={600}
            variant={"body1"}
          >
            Contact Details
          </Typography>
          <Grid
            container
            sx={{
              gap: { xs: 0, md: "-20px" },
            }}
          >
            {" "}
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].phone && (
                  <label className={styles.formLabel}>Phone :</label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].phone && (
                  <label className={styles.formLabel}>
                    {requestData[0].phone}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].mobile && (
                  <label className={styles.formLabel}>Mobile: </label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].mobile && (
                  <label className={styles.formLabel}>
                    {requestData[0].mobile}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                {requestData[0].email && (
                  <label className={styles.formLabel}>Email:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {requestData[0].email && (
                  <label className={styles.formLabel}>
                    {requestData[0].email}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.listItem}>
                <label className={styles.formLabel}>Address:</label>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={styles.listItem}>
                {(requestData[0].addressLine1 ||
                  requestData[0].addressLine2 ||
                  requestData[0].addressLine3) && (
                  <label className={styles.formLabel}>
                    {`${requestData[0].addressLine1}, ${requestData[0].addressLine2}, ${requestData[0].addressLine3}`}
                  </label>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

{/* //  hide this section if the resourceId is 0, i.e verified professional */}
{requestData[0].resourceId !== 0 && (
        <div className={styles.requestDetailsContainer}>
          <Typography
            className={styles.subTitle}
            fontWeight={600}
            variant={"body1"}
          >
            Resource Details 
          </Typography>
          <div className={styles.contentContainerReq}>
            <div className={styles.imageContainer}>
            <img
        src={
          requestData[0]?.resource?.pic
            ? `https://n-ach-file-directory.sgp1.digitaloceanspaces.com/${requestData[0].resource.pic}`
            : require("../../Assets/empty_pro_pic.png")
        }
        alt="Resource Avatar"
        className={styles.avatarImage}
      />
            </div>

            <div className={styles.detailsContainerReq}>
              <Grid
                container
                sx={{
                  gap: { xs: 0, md: "-20px" },
                }}
              >
                {requestData[0]?.resource?.name && (
                <>
                <Grid item xs={4}>
                  <div className={styles.listItem}>
            
                      <label className={styles.formLabel}>Name:</label>
                    
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={styles.listItem}>
                      <label className={styles.formLabel}>{requestData[0].resource.name}</label>
                  </div>
                </Grid>   </>
              )}
                            {requestData[0]?.resource?.mobileNo && (
 
             <>
                <Grid item xs={4}>
                  <div className={styles.listItem}>
                      <label className={styles.formLabel}>Mobile: </label>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={styles.listItem}>
                      <label className={styles.formLabel}> {requestData[0].resource.mobileNo}</label>
                  </div>
                </Grid>
              
                </>
  )}
              
                {requestData[0]?.resource?.email && (  <>
                <Grid item xs={4}>
                  <div className={styles.listItem}>
                      <label className={styles.formLabel}>Email:</label>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={styles.listItem}>
                    {requestData[0].noRes && (
                      <label className={styles.formLabel}> {requestData[0].resource.email}</label>
                    )}
                  </div>
                </Grid>
               </> )}              

              </Grid>
            </div>
          </div>
        </div>
)}
        <div className={styles.buttonContainer}>
          {requestData[0].reqStatus === "COMPLETED" && (
            <Button
              className={styles.submitButton}
              variant="contained"
              onClick={handleDialogOpen}
            >
              Review
            </Button>
          )}
        </div>

        {/* XXXXXXXXXXXXXXXXX   Review Popup Dialog  XXXXXXXXXXXXXX */}
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          fullWidth
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "45rem",
            },
          }}
        >
          <DialogContent>
            <IconButton
              className={styles.closeButton}
              onClick={handleDialogClose}
              style={{ position: "absolute", top: "20px", right: "20px" }}
            >
              <Close />
            </IconButton>

            <div className={styles.reviewDialogContainer}>
              <form onSubmit={handleSubmit}>
                <div style={{ marginTop: "1rem", display: "inline-flex" }}>
                  <OutlinedInput hidden style={{ display: "none" }} />
                  <label style={{ fontWeight: 600, paddingRight: "2rem" }}>
                    Ratings:
                  </label>
                  <FormControlLabel
                    control={
                      <>
                        <input type="number" hidden readOnly />
                        <Rating
                          size="large"
                          precision={1}
                          value={reviewData.rating}
                          onChange={handleRatingChange}
                        />{" "}
                      </>
                    }
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <label className={styles.commentTitle}>Your Review:</label>
                  <div className={styles.listItem}>
                    <OutlinedInput
                      className={styles.formInput}
                      multiline
                      rows={3}
                      size="small"
                      fullWidth
                      name="review"
                      value={reviewData.review}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    type="submit"
                    className={styles.submitButton}
                    variant="contained"
                  >
                    {isReviewPresent ? "Update Review" : "Add Review"}
                  </Button>
                </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  );
};

export default RequestDetails;
