import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import styles from "./Request.module.css";
import { useGetRequestByIdQuery } from "../../Store/Services/RequestService";
import UpdateResource from "./UpdateResource";
import UpdateRequest from "./UpdateRequest";
import RequestImages from "../Shared/RequestImages";

const RequestDetailsDialog = ({ onClose, reqId }) => {
  const [showUpdateResource, setShowUpdateResource] = useState(false);
  const [showUpdateRequest, setShowUpdateRequest] = useState(false);

  const {
    data: requestData,
    isLoading,
    isSuccess: requestSuccess,
    isError,
  } = useGetRequestByIdQuery(reqId);

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth>
        <DialogContent className={styles.dialogContent}>
          {isLoading ? (
            <Typography variant="body1" color="textSecondary"></Typography>
          ) : isError ? (
            <Typography variant="body1" color="error"></Typography>
          ) : (
            requestData &&
            requestData.length > 0 && (
              <div>
                <div className={styles.headingContainer}>
                  <Typography className={styles.modalTitle} variant="h5">
                    {requestData[0].reqName}{" "}
                    {requestData[0].freqName && (
                      <label>{`Frequency: ${requestData[0].freqName}`}</label>
                    )}
                  </Typography>
                  <div>
                    <IconButton
                      className={styles.closeButton}
                      onClick={onClose}
                    >
                      <Close />
                    </IconButton>
                  </div>
                </div>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  className={styles.requesterLabel}
                >
                  Requester : 
                  {`${" "}${requestData[0]?.requester?.firstName || ""} ${
                    requestData[0]?.requester?.lastName || ""
                  }`}
                </Typography>

                <Typography
                  variant="body1"
                  fontWeight={600}
                  className={styles.requesterIDLabel}
                >
                  Request Id : 
                  {`${" "}${requestData[0]?.reqId || ""}`}
                </Typography>

                {/* Service Details */}
                <div className={styles.sectionBox}>
                  <Typography variant="h6" className={styles.sectionTitle}>
                    Request Details
                  </Typography>
                  <div className={styles.detailsContainer}>
                    {requestData[0].day && (
                      <>
                        <Typography className={styles.label}>Date:</Typography>
                        <Typography className={styles.value}>
                          {requestData[0].day}
                        </Typography>
                      </>
                    )}
                    <Typography className={styles.label}>Time:</Typography>
                    <Typography className={styles.value}>
                      {requestData[0].timeSlot}
                    </Typography>
                    <Typography className={styles.label}>Duration:</Typography>
                    <Typography className={styles.value}>
                      {requestData[0].noHrs} hrs
                    </Typography>

                    <Typography className={styles.label}>
                      No of Professionals:
                    </Typography>
                    <Typography className={styles.value}>
                      {requestData[0].noRes}
                    </Typography>

                    <Typography className={styles.label}>Materials:</Typography>
                    <Typography className={styles.value}>
                      {requestData[0].isMaterialsNeeded ? "Yes" : "No"}
                    </Typography>
                    {requestData[0].images?.length > 0 && (
                      <Typography className={styles.label}>Images:</Typography>
                    )}
                    {requestData[0].images?.length > 0 && (
                      <RequestImages images={requestData[0].images} col={4} />
                    )}
                  </div>
                </div>

                {/* Payment Details */}
                <div className={styles.sectionBox}>
                  <Typography variant="h6" className={styles.sectionTitle}>
                    Payment Details
                  </Typography>
                  <div className={styles.detailsContainer}>
                    <Typography className={styles.label}>Price:</Typography>
                    <Typography className={styles.value}>
                      {process.env.REACT_APP_CURRENCY} {requestData[0].amount}
                    </Typography>

                    <Typography className={styles.label}>
                      Payment Method:
                    </Typography>
                    <Typography className={styles.value}>
                      {requestData[0].paymentMethod}
                    </Typography>
                  </div>
                </div>

                <div className={styles.sectionBox}>
                  <Typography variant="h6" className={styles.sectionTitle}>
                    Contact Details
                  </Typography>
                  <div className={styles.detailsContainer}>
                    {requestData[0].phone && (
                      <>
                        <Typography className={styles.label}>Phone:</Typography>
                        <Typography className={styles.value}>
                          {requestData[0].phone}
                        </Typography>
                      </>
                    )}

                    <Typography className={styles.label}>Mobile:</Typography>
                    <Typography className={styles.value}>
                      {requestData[0].mobile}
                    </Typography>

                    <Typography className={styles.label}>Email:</Typography>
                    <Typography className={styles.value}>
                      {requestData[0].email}
                    </Typography>
                    <Typography className={styles.label}>Address:</Typography>
                    <Typography className={styles.value}>
                      {`${requestData[0].addressLine1 || ""}, ${
                        requestData[0].addressLine2 || ""
                      }, ${requestData[0].addressLine3 || ""}, ${
                        requestData[0].stateName || ""
                      }, ${requestData[0].areaName || ""}`.replace(
                        /(,\s)+$/,
                        ""
                      )}
                    </Typography>
                  </div>
                </div>

                {requestData[0].resourceId !== 0 && (
                  <div className={styles.sectionBoxReq}>
                    <Typography variant="h6" className={styles.sectionTitleRes}>
                      Resource Details
                      <IconButton
                        className={styles.editButton}
                        onClick={() => setShowUpdateResource(true)}
                      >
                        <Edit />
                      </IconButton>
                    </Typography>
                    <div className={styles.contentContainerReq}>
                      <div className={styles.imageContainer}>
                        <img
                          src={
                            requestData[0]?.resource?.pic
                              ? `https://n-ach-file-directory.sgp1.digitaloceanspaces.com/${requestData[0].resource.pic}`
                              : require("../../Assets/avatar.png")
                          }
                          alt="Resource Avatar"
                          className={styles.avatarImage}
                        />
                      </div>

                      <div className={styles.detailsContainerReq}>
                        {requestData[0]?.resource?.name && (
                          <div className={styles.detailRow}>
                            <Typography className={styles.label}>
                              Name:
                            </Typography>
                            <Typography className={styles.value}>
                              {requestData[0].resource.name}
                            </Typography>
                          </div>
                        )}
                        {requestData[0]?.resource?.mobileNo && (
                          <div className={styles.detailRow}>
                            <Typography className={styles.label}>
                              Mobile:
                            </Typography>
                            <Typography className={styles.value}>
                              {requestData[0].resource.mobileNo}
                            </Typography>
                          </div>
                        )}
                        {requestData[0]?.resource?.email && (
                          <div className={styles.detailRow}>
                            <Typography className={styles.label}>
                              Email:
                            </Typography>
                            <Typography className={styles.value}>
                              {requestData[0].resource.email}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Remarks */}
                <div className={styles.sectionBox}>
                  <Typography variant="h6" className={styles.sectionTitle}>
                    Remarks
                  </Typography>
                  <div className={styles.detailsContainer}>
                    {requestData[0].resComment ||
                    requestData[0].validComment ? (
                      <>
                        {requestData[0].resComment && (
                          <>
                            <Typography className={styles.label}>
                              Resource Comment:
                            </Typography>
                            <Typography className={styles.value}>
                              {requestData[0].resComment}
                            </Typography>
                          </>
                        )}
                        {requestData[0].validComment && (
                          <>
                            <Typography className={styles.label}>
                              Validation Comment:
                            </Typography>
                            <Typography className={styles.value}>
                              {requestData[0].validComment}
                            </Typography>
                          </>
                        )}
                      </>
                    ) : (
                      <Typography>No remarks available</Typography>
                    )}
                  </div>
                </div>

                <div className={styles.buttonContainer}>
                  {!requestData[0].resourceId && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.button}
                      onClick={() => setShowUpdateResource(true)}
                    >
                      Add Resource
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    onClick={() => setShowUpdateRequest(true)}
                  >
                    Edit Details
                  </Button>
                </div>
              </div>
            )
          )}
        </DialogContent>

        {showUpdateResource && (
          <UpdateResource
            open={showUpdateResource}
            onClose={() => setShowUpdateResource(false)}
            resourceId={requestData[0]?.resourceId}
            reqId={requestData[0]?.reqId}
            calId={requestData[0]?.dateId}
            slotId={requestData[0]?.timeSlotId}
            areaId={requestData[0]?.areaId}
            catId={requestData[0]?.parentCatUuId}
          />
        )}

        <UpdateRequest
          open={showUpdateRequest}
          onClose={() => setShowUpdateRequest(false)}
          reqId={reqId}
        />
      </Dialog>
    </>
  );
};

export default RequestDetailsDialog;
