import React from "react";
import {
  Dialog,
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import SignUpForm from "./SignUpForm";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import styles from "./SignUpForm.module.css";
import CloseIcon from "@mui/icons-material/Close";

const SignUpDialog = ({ open, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onLoginClick = () => {
    if (location.search.includes("redirect")) {
      navigate(`/login?redirect=${searchParams.get("redirect")}`);
    } else {
      navigate("/login");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Paper elevation={3} className={styles.paperStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Button
             onClick={onClose} 
              style={{ position: "absolute", right: "8px", top: "8px" }}
              aria-label="Sign In"
            >
              <CloseIcon />
            </Button>
            <div className={styles.formContainer}>
              <img
                className={styles.logo}
                alt="logo"
                src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/doneRightLogo.png`}
                loading="lazy"
              />
              <Typography variant="h6" component="h3" className={styles.signUp}>
                Sign Up
              </Typography>
              <SignUpForm />
              <Divider sx={{ marginTop: "20px" }}>OR</Divider>
              <Button
                className={styles.formItem}
                onClick={onLoginClick}
                color="secondary"
                fullWidth
              >
                Already have an account?
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default SignUpDialog;
