import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeReviewModal } from "../../Store/Slices/reviewSlice"
import EditReview from "./EditComment";
import DeleteReview from "./DeleteComment";


const ReviewDialog = () => {
    const dispatch = useDispatch();
    const {modalStatus} = useSelector((state) => state.review);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = (event,reason) => {
        if(reason !== 'backdropClick'){
            dispatch(closeReviewModal());
        }
    };

    switch(modalStatus){
        // case "add":
        //     content = roles?.split(",").includes("PRIV_ES_ADD_ROLE") ? <AddReview handleClose={handleClose} /> : null;
        //     break;
        case "edit":
            content = roles?.split(",").includes("PRIV_ES_EDIT_ROLE") ?  <EditReview handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView  = true;
            content = roles?.split(",").includes("PRIV_ES_DELETE_ROLE") ? <DeleteReview handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    };

    return (
        <Dialog
            fullWidth={true}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
};

export default ReviewDialog;