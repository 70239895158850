import React , {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";

const Carousel = () => {
  const [images] = useState([
    `${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/banner/SS1.jpg`,
    `${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/banner/SS2.jpg`,
    `${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/banner/SS3.jpg`,
  ]);

  const limitedImages = images.slice(0, 5);

  const settings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div style={{ textAlign: "center", marginLeft: "0.5rem" }}>
      <Slider {...settings}>
        {limitedImages.map((image, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              src={image}
              alt={`banner${index + 1}`}
              style={{
                width: "100%",
                height: "auto",
                aspectRatio: "16 / 6",
                objectFit: "cover",
              }}
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
