// ... [Other imports]

import { Delete, Edit } from "@mui/icons-material";
import { Box, ButtonGroup, IconButton, Pagination, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useDispatch } from "react-redux";
import { openDeleteUserModal, openEditUserModal } from "../../Store/Slices/userSlice";
import { PaginationDetails } from "../../Utils/Utils";

import styles from "./UserList.module.css";

const UserList = ({ data }) => {
    const dispatch = useDispatch();
    const { page, count, handleChange, _DATA } = PaginationDetails(data);

    const roles = sessionStorage.getItem("roles");

    return (
        <>
<div className={styles.container}>
    {_DATA.currentData() && Array.isArray(_DATA.currentData()) ? (
        _DATA.currentData().map((user) => (
            <div key={user.userId} className={styles.row}>
                <div className={styles.subContainer}>    
                    <AccountCircleIcon
                        sx={{
                            height: 50,
                            width: 50,
                            maxHeight: { xs: 70, md: 80 },
                            maxWidth: { xs: 70, md: 80 },
                        }}
                    />
                    <div className={styles.textContainer}>
                        <Typography className={styles.text1}>
                            {user.userName}
                        </Typography>
                        <Typography className={styles.text2}>
                            {user.userEmail}
                        </Typography>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <ButtonGroup variant="text" aria-label="text button group">
                        {roles?.split(",").includes("PRIV_ES_EDIT_USER") ? (
                            <IconButton onClick={() => dispatch(openEditUserModal(user.userId))}>
                                <Edit />
                            </IconButton>
                        ) : null}
                        {roles?.split(",").includes("PRIV_ES_DELETE_USER") ? (
                            <IconButton onClick={() => dispatch(openDeleteUserModal(user.userId))}>
                                <Delete />
                            </IconButton>
                        ) : null}
                    </ButtonGroup>
                </div>
            </div>
        ))
    ) : (
        <Typography>Error..</Typography>
    )}
</div>

            <Box my={2} display="flex" justifyContent="center">
                <Pagination
                    count={count}
                    size="large"
                    page={page}
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                    className={styles.pagination}
                />
            </Box>
        </>
    );
};

export default UserList;
