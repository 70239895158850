import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import styles from "./Request.module.css";
import {
  useGetRequestByIdQuery,
  useUpdateRequestMutation,
} from "../../Store/Services/RequestService";
import { useGetAllFrequenciesQuery } from "../../Store/Services/FrequencyService";
import { useGetAllStatesQuery } from "../../Store/Services/StateService";
import { useGetAllAreasQuery } from "../../Store/Services/AreaService";
import { useGetAllSlotsQuery } from "../../Store/Services/SlotService";
import { useGetnextTenDaysQuery } from "../../Store/Services/DatesService";
import { useLazyGetResourceByAreaDateTimeQuery } from "../../Store/Services/ResourceService";
import { useGetSubCategoriesByIdQuery } from "../../Store/Services/CategoryService";

const UpdateRequest = ({ open, onClose, reqId }) => {
  const { handleSubmit, control, reset, watch } = useForm();

  const selectedArea = watch("area");
  const selectedDate = watch("date");
  const selectedTimeSlot = watch("timeSlot");

  const { data, isSuccess } = useGetRequestByIdQuery(reqId, { skip: !reqId });
  const { data: frequencies, isLoading: isFrequenciesLoading } =
    useGetAllFrequenciesQuery();
  const { data: states, isLoading: isStatesLoading } = useGetAllStatesQuery();
  const { data: areas, isLoading: isAreasLoading } = useGetAllAreasQuery();
  const { data: dates, isLoading: isDatesLoading } = useGetnextTenDaysQuery();
  const { data: slots, isLoading: isSlotsLoading } = useGetAllSlotsQuery();
  const [
    getProfessionals,
    { data: professionalsData, isLoading: isProfessionalsLoading },
  ] = useLazyGetResourceByAreaDateTimeQuery();
  // const { data: subCategories, isLoading: isSubCategoriesLoading } = useGetSubCategoriesByIdQuery(
  //   selectedCategory,
  //   { skip: !selectedCategory }
  // );
  const { data: subCategories, isLoading: isSubCategoriesLoading } =
    useGetSubCategoriesByIdQuery(data?.[0]?.parentCatUuId, {
      skip: !data?.[0]?.parentCatUuId,
    });
  const [updateRequest, { isLoading: isUpdating }] = useUpdateRequestMutation();

  useEffect(() => {
    if (selectedArea && selectedDate && selectedTimeSlot) {
      getProfessionals({
        areaId: selectedArea,
        date: selectedDate,
        timeSlotId: selectedTimeSlot,
      });
    }
  }, [selectedArea, selectedDate, selectedTimeSlot, getProfessionals]);

  const onSubmit = async (formData) => {
    const payload = {
      catId: formData.category,
      freqId: formData.frequency,
      remarks: formData.remarks || "",
      noHrs: formData.noOfHours,
      noRes: formData.noOfRes,
      isMaterialsNeeded: formData.materialsRequired === "yes" ? 1 : 0,
      resourceId: formData.professional,
      dateId: formData.dateId,
      date: formData.date,
      timeSlotId: formData.timeSlot,
      areaId: formData.area,
      stateId: formData.state,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2 || "",
      addressLine3: formData.addressLine3 || "",
    };
    try {
      const response = await updateRequest({ ...payload, reqId }).unwrap();
      console.log("Update Successful:", response);
      onClose();
    } catch (error) {
      console.error("Update Failed:", error);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (isSuccess && data?.length) {
      const request = data[0];
      console.log("Request data for initial values:", request);

      reset({
        category: request.catId,
        frequency: request.freqId || "",
        noOfRes: request.noRes || "",
        noOfHours: request.noHrs,
        materialsRequired: request.isMaterialsNeeded ? "yes" : "no",
        remarks: request.resComment || "",
        state: request.stateId,
        area: request.areaId,
        addressLine1: request.addressLine1,
        addressLine2: "",
        addressLine3: "",
        date: request.day,
        timeSlot: request.timeSlotId || "",
        professional: request.resourceId || "",
      });
    }
  }, [isSuccess, data, reset, subCategories]);

  const renderCategoryOptions = (categories, level = 0) => {
    return categories.map((category) =>
      [
        category.subCategories && category.subCategories.length > 0 ? (
          <MenuItem key={category.catId} value="" disabled>
            {"—".repeat(level)} {category.catDisplay}
          </MenuItem>
        ) : (
          <MenuItem key={category.catId} value={category.catId}>
            {"—".repeat(level)} {category.catDisplay}
          </MenuItem>
        ),
        category.subCategories &&
          category.subCategories.length > 0 &&
          renderCategoryOptions(category.subCategories, level + 1),
      ].filter(Boolean)
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <div className={styles.headingContainer}>
          <div className={styles.dialogHeader}>
            <Typography
              fontWeight={600}
              className={styles.dialogTitle}
              variant="h6"
            >
              Update Request
            </Typography>
          </div>
          <IconButton onClick={handleClose} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* Category */}
            <Grid item xs={12} sm={12}>
              <label className={styles.formLabel}>Category:</label>
              <Controller
                name="category"
                control={control}
                render={({ field }) => {
                  console.log("Selected category field:", field.value);
                  return (
                    <Select
                      {...field}
                      fullWidth
                      displayEmpty
                      size="small"
                      value={field.value || ""}
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      {isSubCategoriesLoading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : (
                        subCategories?.map((subCat) => (
                          <MenuItem key={subCat.catId} value={subCat.catId}>
                            {subCat.catDisplay}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  );
                }}
              />
            </Grid>
            {/* No of NoOfRes */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>No of Resources:</label>

              <Controller
                name="noOfRes"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    displayEmpty
                    size="small"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      {isSubCategoriesLoading
                        ? "Loading..."
                        : "Select No of Res"}
                    </MenuItem>
                    {subCategories?.[0]?.noResList?.map((noOfRes) => (
                      <MenuItem key={noOfRes.noResId} value={noOfRes.noResId}>
                        {noOfRes.noRes}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                defaultValue={data?.[0]?.noRes || ""}
              />
            </Grid>
            {/* No of Hours */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>No of Hours:</label>

              <Controller
                name="noOfHours"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth displayEmpty size="small">
                    <MenuItem value="" disabled>
                      {isSubCategoriesLoading
                        ? "Loading..."
                        : "Select No of Hours"}
                    </MenuItem>
                    {subCategories?.[0]?.noHrsList?.map((noOfHours) => (
                      <MenuItem
                        key={noOfHours.noHrsId}
                        value={noOfHours.noHrsId}
                      >
                        {noOfHours.noHrs}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                defaultValue={data?.[0]?.noHrs || ""}
              />
            </Grid>
            {/* Cleaning Materials Required */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>
                Cleaning Materials Required:
              </label>

              <Controller
                name="materialsRequired"
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>Frequency:</label>

              <Controller
                name="frequency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    displayEmpty
                    size="small"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      {isFrequenciesLoading ? "Loading..." : "Select Frequency"}
                    </MenuItem>
                    {frequencies.map((freq) => (
                      <MenuItem key={freq.freqId} value={freq.freqId}>
                        {freq.freqName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            {/* Remarks */}
            <Grid item xs={12}>
              <label className={styles.formLabel}>Admin Remarks:</label>

              <Controller
                name="remarks"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Remarks"
                    fullWidth
                    size="small"
                    multiline
                    rows={1}
                  />
                )}
              />
            </Grid>
            {/* State */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>State:</label>

              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth displayEmpty size="small">
                    <MenuItem value="" disabled>
                      {isStatesLoading ? "Loading..." : "Select State"}
                    </MenuItem>
                    {states &&
                      states.map((state) => (
                        <MenuItem key={state.stateId} value={state.stateId}>
                          {state.state}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
            {/* Area */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>Area:</label>

              <Controller
                name="area"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth displayEmpty size="small">
                    <MenuItem value="" disabled>
                      {isAreasLoading ? "Loading..." : "Select Area"}
                    </MenuItem>
                    {areas &&
                      areas.map((area) => (
                        <MenuItem key={area.areaId} value={area.areaId}>
                          {area.desc}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
            {/* Address Line 1 */}
            <Grid item xs={12}>
              <label className={styles.formLabel}>Address:</label>
              <Controller
                name="addressLine1"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Address Line 1"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Address Line 2 */}
            <Grid item xs={12}>
              <Controller
                name="addressLine2"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Address Line 2"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Address Line 3 */}
            <Grid item xs={12}>
              <Controller
                name="addressLine3"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Address Line 3"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Date */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>Date:</label>

              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth displayEmpty size="small">
                    <MenuItem value="" disabled>
                      {isDatesLoading ? "Loading dates..." : "Select a Date"}
                    </MenuItem>
                    {dates &&
                      dates.map((date) => (
                        <MenuItem key={date.id} value={date.date}>
                          {date.date}{" "}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
            {/* Time */}
            <Grid item xs={12} sm={6}>
              <label className={styles.formLabel}>Time Slot:</label>

              <Controller
                name="timeSlot"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    displayEmpty
                    size="small"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      {isSlotsLoading
                        ? "Loading slots..."
                        : "Select a Time Slot"}
                    </MenuItem>
                    {slots &&
                      slots.map((slot) => (
                        <MenuItem key={slot.slotId} value={slot.slotId}>
                          {slot.slot}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
            {/* Professional */}
            <Grid item xs={12} sm={12}>
              <label className={styles.formLabel}>Professional:</label>

              <Controller
                name="professional"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth displayEmpty size="small">
                    <MenuItem value="" disabled>
                      {isProfessionalsLoading
                        ? "Loading professionals..."
                        : "Select Professional"}
                    </MenuItem>
                    {professionalsData &&
                      professionalsData.map((prof) => (
                        <MenuItem key={prof.id} value={prof.id}>
                          {prof.name}{" "}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
            ;{/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateRequest;
