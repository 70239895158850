import React, { useState } from "react";
import {
  Typography,
  Button,
  IconButton,
  OutlinedInput,
  Autocomplete,
  TextField,
  Avatar,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { openAddResourceModal } from "../../Store/Slices/resourceSlice";
import { useCreateResourceMutation } from "../../Store/Services/ResourceService";
import { useGetAllAreasQuery } from "../../Store/Services/AreaService";
import { useGetAllUserQuery } from "../../Store/Services/UserService";
import { useGetAllTopCategoriesQuery } from "../../Store/Services/CategoryService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./Resource.module.css";
import { useUploadResImageMutation } from "../../Store/Services/ImageService";
import { Close, Edit } from "@mui/icons-material";

const schema = yup.object({
  // userId: yup.string().trim().required(" *"),
  idNo: yup.string().trim().required("*"),
  telNo: yup.string().trim().required("*"),
  mobileNo: yup.string().trim().required("*"),
  // resAreaId: yup.string().trim().required("*"),
  // catId: yup.string().trim().required("*"),
  // slotId: yup.string().trim().required("*"),
  email: yup
    .string()
    .trim()
    .required("* ")
    .email("User Email must be a valid email"),
  contactNum: yup.string(),
});

function AddResource({ handleClose, uploadedImage }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ resolver: yupResolver(schema) });

  const [createResource] = useCreateResourceMutation();

  const dispatch = useDispatch();

  const { data: categoriesData } = useGetAllTopCategoriesQuery();
  const categories = categoriesData || [];
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const handleCategoryChange = (_, newValue) => {
    setSelectedCategories(newValue);
  };

  const { data: areasData } = useGetAllAreasQuery();
  const areas = areasData || [];
  const [selectedAreas, setSelectedAreas] = React.useState([]);
  const handleAreaChange = (_, newValue) => {
    setSelectedAreas(newValue);
  };

  const { data: usersData } = useGetAllUserQuery();
  const users = usersData || [];
  const [selectedNames, setSelectedNames] = React.useState();

  const handleNameChange = (_, newValue) => {
    setSelectedNames(newValue);

    setValue("userId", newValue.userId);
  };


  const onSubmit = async (data) => {
    // console.log("profileImage in onSubmit:", profileImage);
    // console.log("userId:", {userId: data.userId});
    
    const dataSet = {
      name: selectedNames
        ? [`${selectedNames.firstName} ${selectedNames.lastName}`]
        : [],
      userId: data.userId,
      idNo: data.idNo,
      telNo: data.telNo,
      mobileNo: data.mobileNo,
      email: data.email,
      resourceArea: selectedAreas.map((area) => area.areaId),
      resourceCat: selectedCategories.map((cat) => cat.catId),
      pic: profileImage,
    };

    try {
      const respond = await createResource(dataSet).unwrap();

      dispatch(openAddResourceModal(false));
      toast.success("Resource successfully created!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to save the Resource: ", err);
if (err.data && err.data._embedded && err.data._embedded.errors && err.data._embedded.errors[0]) {
  toast.error(err.data._embedded.errors[0].message, {
    position: "bottom-right",
  });
} else {
  toast.error("An error occurred while saving the Resource.", {
    position: "bottom-right",
  });
}

    }
    handleClose();
  };

  const [uploadImage] = useUploadResImageMutation();
  const [profileImage, setProfileImage] = useState();

  const onImageUpload = async (e) => {
    var data = new FormData();
    data.append("images", e.target.files[0]);

    try {
      const result = await uploadImage(data).unwrap();
      // console.log("result", result);

      if (result.length > 0 && result[0].length > 0) {
        // console.log("Image URL:", result[0][0]);
        setProfileImage(result[0][0]);
      } else {
        console.error("No valid image URLs found in the result:", result);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Add Resource
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <label htmlFor="contained-button-file">
        <div
          className={styles.avatarContainer}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 5px 5px 0",
          }}
        >
          <form encType="multipart/form-data">
            <Avatar
              className={styles.avatar}
              style={{
                width: "110px",
                height: "110px",
                border: "solid 1px #5A9BD8",
                marginTop: "30px",
              }}
              src={
                profileImage
                  ? `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                  : `${process.env.REACT_APP_IMAGE_URL}Default/User_Avatar.jpg`
              }
              onLoad={() =>
                console.log(
                  "Avatar loaded:",
                  `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                )
              }
              onError={() => console.error("Error loading Avatar")}
            />

            <div style={{ margin: "-2rem 0 0 5rem" }}>
              <IconButton
                outlined="contained"
                style={{
                  width: "2rem",
                  borderRadius: "3rem",
                  backgroundColor: "#5A9BD8",
                }}
                component="span"
              >
                <input
                  onChange={onImageUpload}
                  sx={{ "&": { marginTop: "0px" }, justifyContent: "right" }}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                />
                <Edit sx={{ width: "1rem", height: "1rem", color: "white" }} />
              </IconButton>
            </div>
          </form>
        </div>
      </label>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Name:</label>
            {errors.userId && errors.userId.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <Autocomplete
              size="small"
              id="name-select"
              options={users}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={handleNameChange}
              value={selectedNames}
              renderInput={(params) => (
                <TextField
                  className={styles.formInput}
                  {...params}
                  label="Select Name"
                  variant="outlined"
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>NIC No:</label>
            {errors.idNo && errors.idNo.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              {...register("idNo")}
              className={styles.formInput}
              placeholder="Enter NIC"
              size="small"
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Email Address:</label>
            {errors.email && errors.email.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              {...register("email")}
              className={styles.formInput}
              placeholder="Enter Email Address"
              size="small"
              fullWidth
            />
          </div>
        </Grid>

        <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} md={6} sx={{ width: "50%" }}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}>Telephone Number:</label>
              {errors.telNo && errors.telNo.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("telNo")}
                className={styles.formInput}
                placeholder="Enter Telephone Number"
                size="small"
                type="number"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ width: "50%" }}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}>Mobile Number:</label>
              {errors.mobileNo && errors.mobileNo.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("mobileNo")}
                className={styles.formInput}
                placeholder="Enter Mobile Number"
                size="small"
                type="number"
                fullWidth
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Resource Areas:</label>
            {errors.resAreaId && errors.resAreaId.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <Autocomplete
              size="small"
              multiple
              id="area-select"
              options={areas}
              getOptionLabel={(option) => option.desc}
              onChange={handleAreaChange}
              value={selectedAreas}
              renderInput={(params) => (
                <TextField
                  className={styles.formInput}
                  {...params}
                  label="Select Areas"
                  variant="outlined"
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Categories:</label>
            {errors.catId && errors.catId.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <Autocomplete
              size="small"
              multiple
              id="category-select"
              options={categories}
              getOptionLabel={(option) => option.catName}
              onChange={handleCategoryChange}
              value={selectedCategories}
              renderInput={(params) => (
                <TextField
                  className={styles.formInput}
                  {...params}
                  label="Select Categories"
                  variant="outlined"
                />
              )}
            />
          </div>
        </Grid>

        <div className={styles.buttonContainer}>
          <Button type="submit" variant="contained" className={styles.submitButton}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
}

export default AddResource;
