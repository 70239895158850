import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import LoginForm from "./LoginForm";
import { ArrowBack } from "@mui/icons-material";
import styles from "./LoginForm.module.css";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";

const LoginDialog = ({ open, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onRegisterClick = () => {
    if (location.search.includes("redirect")) {
      navigate(`/register?redirect=${searchParams.get("redirect")}`);
    } else {
      navigate("/register");
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Paper elevation={3} className={styles.paperStyle}>
        <Grid container>
          <Grid item xs={12}>
            {/* {location.pathname !== "/" && (
              <Button
                onClick={() => navigate("/")}
                style={{ position: "absolute", right: "48px", top: "8px" }}
                aria-label="Home"
              >
                <HomeIcon style={{ marginRight: "4px" }} />
              </Button>
            )} */}
            <Button
              onClick={() => {
                if (location.pathname.startsWith("/checkout")) {
                  navigate("/");
                } else {
                  onClose();
                }
              }}
              style={{ position: "absolute", right: "8px", top: "8px" }}
              aria-label="Close"
            >
              <CloseIcon />
            </Button>
            <div className={styles.formContainer}>
              <img
                className={styles.logo}
                alt="logo"
                src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/doneRightLogo.png`}
                loading="lazy"
              />
              <Typography variant="h6" component="h3" className={styles.login}>
                Sign In
              </Typography>
              <LoginForm onClose={onClose} />
              <Button
                className={styles.formItem}
                onClick={onRegisterClick}
                color="secondary"
                fullWidth
              >
                Create an account
              </Button>
              <Divider sx={{ mt: -3, mb: -2 }}>OR</Divider>
              <Button
                className={styles.formItem}
                color="secondary"
                fullWidth
                component={Link}
                to="/forgot-password"
              >
                Forgot Password?
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default LoginDialog;
